import { createStyles, Grid, Typography } from '@material-ui/core'
import { Theme } from '@material-ui/core/styles'
import { DataGrid, GridColDef, GridValueFormatterParams } from '@material-ui/data-grid'
import { makeStyles } from '@material-ui/styles'
import { Deal, Merchant, User } from '@yodo/types'
import firebase from 'firebase/app'
import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useDocumentData } from 'react-firebase-hooks/firestore'
import Loading from 'src/components/Molecules/Loading'
import ListItemDealDates from 'src/components/Views/Deals/ListItem/dates'
import ListItemDealInfo from 'src/components/Views/Deals/ListItem/infos'
import ListItemDealPrices from 'src/components/Views/Deals/ListItem/prices'
import { FirebaseContext } from 'src/utils/firebase'
import { formatStringToDate } from 'src/utils/libs/date'

type Props = {
  sales: any[]
}

export default function SalesList({ sales }: Props) {
  const classes = useStyles()
  const { firebase, token } = useContext(FirebaseContext)
  const [displayedDeals, setDisplayedDeals] = useState<any | undefined>([])
  const [merchant] = useDocumentData<Merchant>(
    firebase.firestore().doc(`merchants/${token?.claims?.merchantId}`),
    {
      idField: 'id'
    }
  )
  const [loading, setLoading] = useState(true)

  const gridWrapperRef = useRef<HTMLDivElement>(null)
  useLayoutEffect(() => {
    const gridDiv = gridWrapperRef.current
    if (gridDiv) {
      const gridEl: HTMLDivElement = gridDiv.querySelector('div')!
      if (gridEl) {
        gridEl.style.height = ''
      }
    }
  })

  useEffect(() => {
    const transform = async () => {
      if (sales) {
        let newSales: any[] = []
        await Promise.all(
          sales.slice().map(async (sale) => {
            const saleData = sale.data()
            let customerInfo = ''
            let customerAddressInfo = ''
            const customer = await firebase
              .firestore()
              .collection('users')
              .doc(saleData.customer.id)
              .get()

            const realDeal = await firebase.firestore().doc(saleData.deal.path).get()

            if (customer.exists) {
              const customerData = customer.data() as User
              customerInfo = `${customerData.displayName} (${customerData.phoneNumber})`
              customerAddressInfo = `${customerData?.address ?? ''} ${
                customerData?.postalCode ?? ''
              } ${customerData?.locality ?? ''}`
            }

            let state = ''
            if (saleData.state === 'used') {
              state = formatStringToDate(
                new Date(saleData.updatedAt.seconds * 1000).toISOString(),
                {
                  day: '2-digit',
                  month: '2-digit',
                  year: 'numeric',
                  hour: 'numeric',
                  minute: 'numeric'
                }
              )
            } else if (saleData.state === 'payed') {
              const now = new Date()
              const consumptionDateTimeEnd = saleData.dealData
                .consumptionDateTimeEnd as firebase.firestore.Timestamp
              if (now > new Date(consumptionDateTimeEnd.seconds * 1000)) {
                state = 'Pas venu'
              } else if (now <= new Date(consumptionDateTimeEnd.seconds * 1000)) {
                const pickupLogs = await sale.ref.collection('pickupLogs').get()
                if (pickupLogs.docs.length) {
                  state = 'Encore en cours - ' + saleData.quantityUsed + ' récupéré(s)'
                } else {
                  state = 'Encore en cours - ouvert'
                }
              }
            }

            let variantName = ''
            let currentVariant = null
            if (saleData.variantId) {
              const index = saleData.dealData.variants.findIndex(
                (variant) => variant.id === saleData.variantId
              )
              if (index !== -1) {
                currentVariant = saleData.dealData.variants[index]
                variantName = `${currentVariant.name} ${currentVariant.description}`
              }
            }

            let reimbursementState = ''
            if (saleData.reimbursementDate && saleData.priceTotalMerchant) {
              reimbursementState =
                'CHF ' +
                saleData.priceTotalMerchant +
                ' | ' +
                formatStringToDate(
                  new Date(saleData.reimbursementDate.seconds * 1000).toISOString(),
                  {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric'
                  }
                )
            }

            const rowValues = {
              id: sale.id,
              dealId: saleData.dealData.id,
              updateAtOriginal: saleData.createdAt,
              updatedAt: formatStringToDate(
                new Date(saleData.createdAt.seconds * 1000).toISOString(),
                {
                  day: '2-digit',
                  month: '2-digit',
                  year: 'numeric',
                  hour: 'numeric',
                  minute: 'numeric'
                }
              ),
              quantity: saleData.quantity,
              customer: customerInfo,
              customerAddress: customerAddressInfo,
              state: state,
              variantId: variantName,
              reimbursementState
            }

            const _index = newSales.findIndex(function (item) {
              return item.dealId === realDeal.id
            })

            if (_index > -1) {
              newSales[_index].sales.push(rowValues)
            } else {
              newSales.push({
                dealId: realDeal.id,
                sales: [rowValues],
                deal: realDeal.data(),
                listType: realDeal.ref.parent.path
              })
            }
          })
        ).then(() => {
          const newDisplays = newSales.sort((deal1, deal2) => {
            const sales1 = deal1.sales.sort((_a: any, _b: any) =>
              _a.updateAtOriginal < _b.updateAtOriginal ? 1 : -1
            )
            const sales2 = deal2.sales.sort((_a: any, _b: any) =>
              _a.updateAtOriginal < _b.updateAtOriginal ? 1 : -1
            )

            return sales1[0].updateAtOriginal.seconds < sales2[0].updateAtOriginal.seconds ? 1 : -1
          })
          setDisplayedDeals(newDisplays)
          setLoading(false)
        })
      } else {
        setLoading(false)
      }
    }

    if (sales && sales.length) {
      // transform
      setLoading(true)
      transform()
    }
  }, [sales])

  const headerDeal = (deal: Deal, listType: any) => {
    // @ts-ignore
    return (
      <Grid
        key={deal.id}
        container
        spacing={3}
        style={{ paddingTop: '25px', paddingBottom: '25px' }}>
        <Grid item md={3} xs={6}>
          <ListItemDealInfo deal={deal} merchant={merchant} listType={listType} />
        </Grid>
        <Grid item md={3} xs={6}>
          <ListItemDealDates deal={deal} />
        </Grid>

        <Grid item md={3} xs={12}>
          <ListItemDealPrices deal={deal} />
        </Grid>
      </Grid>
    )
  }

  if (loading) {
    return <Loading />
  }

  return displayedDeals.map(({ sales, deal, listType }: any) => {
    if (!deal) return
    const columns = getColumnDefinition({ deal, merchant })
    const displayedSales = sales.slice()
    return (
      <div className={classes.dealBlock}>
        <div>{headerDeal(deal, listType)}</div>
        <div ref={gridWrapperRef}>
          {/*<GlobalCss />*/}
          <DataGrid
            rows={displayedSales}
            loading={false}
            rowHeight={60}
            autoHeight={true}
            autoPageSize
            pageSize={10}
            density="standard"
            columns={columns}
            disableSelectionOnClick
            checkboxSelection={false}
            hideFooterPagination
            hideFooterRowCount
            hideFooter
          />
        </div>
      </div>
    )
  })
}

type Args = {
  deal: Deal
  merchant: Merchant | undefined
}

function getColumnDefinition({ deal, merchant }: Args): GridColDef[] {
  let columnsDefinition = [
    {
      field: 'updatedAt',
      width: 220,
      headerName: "Date d'achat",
      renderCell: (params: GridValueFormatterParams) => {
        return <Typography>{params.value}</Typography>
      }
    },
    {
      field: 'customer',
      width: 320,
      headerName: 'Utilisateur',
      renderCell: (params: GridValueFormatterParams) => {
        return <Typography>{params.value}</Typography>
      }
    },
    {
      field: 'quantity',
      width: 60,
      headerName: '#',
      renderCell: (params: GridValueFormatterParams) => {
        return <Typography>{params.value}</Typography>
      }
    },
    {
      field: 'state',
      width: 320,
      headerName: 'Validation dans mon commerce',
      renderCell: (params: GridValueFormatterParams) => {
        return <Typography>{params.value}</Typography>
      }
    },
    {
      field: 'reimbursementState',
      width: 320,
      headerName: 'Remboursement',
      renderCell: (params: GridValueFormatterParams) => {
        return <Typography>{params.value}</Typography>
      }
    }
  ]

  if (merchant?.showClientPersonalData) {
    columnsDefinition.splice(3, 0, {
      field: 'customerAddress',
      width: 320,
      headerName: 'Adresse',
      renderCell: (params: GridValueFormatterParams) => {
        return <Typography>{params?.value ?? ''}</Typography>
      }
    })
  }

  if (deal.hasVariants) {
    columnsDefinition.splice(1, 0, {
      field: 'variantId',
      width: 150,
      headerName: 'Variantes',
      renderCell: (params: GridValueFormatterParams) => {
        return <Typography>{params.value}</Typography>
      }
    })
  }

  return columnsDefinition
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dealHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: 10,
      backgroundColor: theme.palette.primary.main,
      color: 'black'
    },
    dealBlock: {
      marginBottom: 60
    }
  })
)
